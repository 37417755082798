<template>
  <div class="elv-base-text-select-item">
    <span v-if="props.title" class="elv-base-text-select-item__label">{{ props.title }}:</span>
    <el-select
      v-bind="$attrs"
      placeholder=" "
      :empty-values="[null, undefined]"
      :popper-class="`elv-base-text-select-popper ${props.popperClass}`"
      class="elv-base-text-select-list"
    >
      <el-option
        v-for="item in props.options"
        :key="item.value"
        :label="transformI18n(item.label)"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
<script setup lang="ts">
import { transformI18n } from '@/i18n'

type OptionType = {
  value: string
  label: string
}

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  options: {
    type: Array as () => OptionType[],
    default: () => []
  },
  popperClass: {
    type: String,
    default: ''
  }
})
</script>
<style lang="scss">
.elv-base-text-select-item {
  padding-right: 16px;
  display: flex;
  align-items: center;

  &:last-child {
    border-right: none;
  }

  .elv-base-text-select-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
</style>
